// =============================================================================
//
//  Page Designer Layouts
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width) {
    .widget {

        // Layouts widths Desktop

        &-four-columns {
            @include flex(0 0 calc(#{$widget-layout-full__width / 4} - #{$widget-four-columns-base__padding}));
        }

        &-two-columns {
            @include flex(0 0 calc(#{$widget-layout-full__width / 2} - #{$widget-double-column-base__pading}));
        }

        // Layouts Defaut Full Widths Override Mobile

        &-single-column {
            .experience-hsngassets-herobanner {
                margin: 0;
            }
        }

        &-category-matrix-inner-wrapper {
            margin: 0;
        }


        //  Layouts Default Spacing Top and Bottom Desktop

        &-layout-single-column {
            .layout-top-spacing {
                margin-top: $widget-single-column__margin-top--large;
            }

            .layout-bottom-spacing {
                margin-bottom: $widget-single-column__margin-bottom--large;
            }

            .wrapper:not(.newsletter) {
                padding: 0;
            }
        }

        &-layout-double-column {
            .layout-top-spacing {
                margin-top: $widget-double-column__margin-top--large;
            }

            .layout-bottom-spacing {
                margin-bottom: $widget-double-column__margin-bottom--large;
            }
        }

        &-layout-html-block-column {
            .layout-top-spacing {
                margin-top: $widget-html-block-column__margin-top--large;
            }

            .layout-bottom-spacing {
                margin-bottom: $widget-html-block-column__margin-bottom--large;
            }
        }

        &-layout-accordion {
            .layout-top-spacing {
                margin-top: $widget-accordion-layout__margin-top--large;
            }

            .layout-bottom-spacing {
                margin-bottom: $widget-accordion-layout__margin-bottom--large;
            }
        }

        &-layout-article-grid {
            .layout-top-spacing {
                margin-top: $widget-article-grid-layout__margin-top--large;
            }

            .layout-bottom-spacing {
                margin-bottom: $widget-article-grid-layout__margin-bottom--large;
            }
        }

        &-layout-blog-carousel {
            .layout-top-spacing {
                margin-top: $widget-blog-carousel-layout__margin-top--large;
            }

            .layout-bottom-spacing {
                margin-bottom: $widget-blog-carousel-layout__margin-bottom--large;
            }
        }

        &-layout-category-grid {
            .layout-top-spacing {
                margin-top: $widget-category-grid-layout__margin-top--large;
            }

            .layout-bottom-spacing {
                margin-bottom: $widget-category-grid-layout__margin-bottom--large;
            }
        }

        &-layout-category-matrix {
            .layout-top-spacing {
                margin-top: $widget-category-matrix-layout__margin-top--large;
            }

            .layout-bottom-spacing {
                margin-bottom: $widget-category-matrix-layout__margin-bottom--large;
            }
        }

        &-layout-product-grid {
            .layout-top-spacing {
                margin-top: $widget-product-grid-layout__margin-top--large;
            }

            .layout-bottom-spacing {
                margin-bottom: $widget-product-grid-layout__margin-bottom--large;
            }
        }

        // Affiliate Grid Layout Desktop

        &-layout-affiliate-grid {
            .layout-top-spacing {
                margin-top: $widget-affiliate-grid-layout__margin-top--large;
            }

            .layout-bottom-spacing {
                margin-bottom: $widget-affiliate-grid-layout__margin-bottom--large;
            }
        }

        &-affiliate-grid {
            &-inner-wrapper {
                .experience {
                    &-region {
                        @include flex-wrap(wrap);
                    }

                    &-hsngassets-affiliatetile {
                        @include flex(0 0 33.33%);
                    }
                }
            }
        }

        // Product Grid Layout Desktop

        &-product-grid {
            &-heading {
                @include get-font('large');
                padding: $widget-product-grid-heading__padding--large;
                margin-bottom: $widget-product-grid-heading__margin-bottom--large;
            }

            &-inner-wrapper {
                .experience-components {
                    @include flexbox;
                    @include flex-flow(row wrap);
                    margin: $experience-components__margin--large;
                }

                .experience-hsngassets-producttile {
                    width: $experience-hsngassets-producttile__width--large;
                    padding: $experience-hsngassets-producttile__padding--large;
                    border-top: none;
                    margin-bottom: $experience-hsngassets-producttile__margin-bottom--large;

                    .product {
                        height: 100%;
                    }
                }
            }
        }

        &-article-page-layout {
            .widget-product-grid-inner-wrapper {
                .experience-hsngassets-producttile {
                    width: $experience-hsngassets-article-producttile__width--large;
                }
            }
        }

        &-accordion-column {
            @include flex($widget-accordion-layout__flex--large);
        }

        &-three-columns {
            @include flex(0 0 calc(#{$widget-layout-full__width / 3} - #{$widget-columns-base__padding}));
        }

        // Category Grid Layout Desktop

        &-layout-category-grid-inner {
            &.has-diagonal-background {
                padding: $widget-layout-category-grid-inner__padding--large;
            }

            &:not(.has-diagonal-background) {
                .widget-four-columns {
                    @include flex(0 0 calc(#{$widget-layout-full__width / 4} - #{$widget-four-columns-base__padding}));
                }
            }
        }

        &-category-grid {
            .widget-four-columns {
                padding: 0;
            }
        }

        // Category Matrix Layout Desktop

        &-category-matrix-side-container,
        &-category-matrix-main-container {
            @include flex(0 0 calc(#{$widget-layout-full__width / 2} - #{$widget-category-matrix-layout-base__padding}));
        }

        &-category-matrix-main-container {
            padding-bottom: $widget-category-matrix-layout-base__padding;
        }

        &-category-matrix-side-container {
            padding-bottom: $widget-category-matrix-layout-base__padding;

            .widget-category-martrix-column {
                @include flex(0 0 $widget-layout-full__width);

                &:first-child {
                    @include align-self(flex-start);
                    padding-bottom: $widget-category-matrix-layout-base__padding;
                }

                &:last-child {
                    @include align-self(flex-end);
                    padding-top: $widget-category-matrix-layout-base__padding;
                }
            }
        }

        &-category-matrix-bottom-container {
            padding-top: $widget-category-matrix-layout-base__padding;

            .widget-category-martrix-column {
                @include flex(0 0 calc(#{$widget-layout-full__width / 2} - #{$widget-category-matrix-layout-base__padding}));
            }
        }

        // Blog Carousel Layout Desktop

        &-blog-carousel-container {
            padding: $widget-blog-carousel-container__padding--large;

            .slick-slide {
                margin: 0  $widget-blog-carousel-item__margin--large;
            }
        }

        &-blog-carousel-image-wrapper {
            width: $widget-blog-carousel-image-wrapper__width--large;
            height: $widget-blog-carousel-image-wrapper__height--large;
        }

        &-blog-heading-text {
            @include get-font('xlarge');
        }

        &-blog-subheading-wrapper {
            text-align: $widget-blog-carousel-subheading-link__text-align--large;

            .widget-blog-subheading-link {
                @include get-font('small');
                font-family: $widget-blog-carousel-subheading-link__font-family;
            }
        }

        &-blog-carousel {
            .slick-blog-carousel {
                padding-bottom: $widget-slick-blog-carousel__padding-bottom--large;
                margin-top: $widget-slick-blog-carousel__margin-top--large;

                .slick-list {
                    width: 100%;
                }
            }

            .slick-dots {
                display: none !important;
            }
        }

        // Article Grid Layout Mobile

        &-article-grid {
            .widget-three-columns {
                padding: $widget-article-grid__padding--large;

                &:nth-child(3n+1) {
                    margin-right: $widget-article-three-columns-base__margin;
                }

                &:nth-child(3n+2) {
                    margin: 0 $widget-article-three-columns-base__margin;
                }

                &:nth-child(3n+3) {
                    margin-left: $widget-article-three-columns-base__margin;
                }
            }
        }
        // Article Page Type Layout Width

        &-article-page-layout {
            .container:not(.widget-category-grid) {
                width: $widget-article-page-layout__width--large;
                max-width: $widget-article-page-layout__max-width--large;
            }
        }
    }
}

// IE compatibility
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .widget {
        &-four-columns {
            @include flex($ie-four-columns-layout__flex--large);
        }

        &-two-columns,
        &-category-matrix-main-container,
        &-category-matrix-side-container {
            @include flex($ie-two-columns-layout__flex--large);
        }

        &-three-columns {
            @include flex($ie-three-columns-layout__flex--large);
        }

        &-layout-category-grid-inner {
            &:not(.has-diagonal-background) {
                .widget-four-columns {
                    @include flex($ie-four-columns-layout__flex--large);
                }
            }
        }

        &-category-matrix-bottom-container {
            .widget-category-martrix-column {
                @include flex($ie-two-columns-layout__flex--large);
            }
        }
    }
}
