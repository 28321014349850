// =============================================================================
//
//  Page Designer Widgets
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width) and (max-width: $desktop-width-large) {
    .widget {
        &-product-grid {
            &-inner-wrapper {
                .experience-hsngassets-producttile {
                    width: $experience-hsngassets-producttile__width--medium-large;
                }
            }
        }
    }
}
