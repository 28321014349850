// =============================================================================
//
//  Page Designer Layouts Variables
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

// General

$widget-layout-full__width: 100%;
$widget-layout-base__padding: $base-padding * 1.8;
$widget-columns-base__padding: ($base-padding * 0.9);
$widget-four-columns-base__padding: $base-padding * 1.2;
$widget-layout-negative-mobile__margin: 0 #{-($base-margin * 1.2)};

// Single Column

$widget-single-column__margin-top: 0;
$widget-single-column__margin-top--large: 0;
$widget-single-column__margin-bottom: $base-margin * 1.5;
$widget-single-column__margin-bottom--large: $base-margin * 0.5;

// Html Block Layout

$widget-html-block-column__margin-top: $base-margin;
$widget-html-block-column__margin-top--large: $base-margin;
$widget-html-block-column__margin-bottom: $base-margin;
$widget-html-block-column__margin-bottom--large: $base-margin;

// Cat Matrix Layout

$widget-category-matrix-layout-base__padding: $base-padding * 0.7;
$widget-category-matrix-bottom-container__padding-top: $widget-category-matrix-layout-base__padding / 4;
$widget-category-matrix-main-container__padding-bottom: $widget-category-matrix-layout-base__padding / 2;
$widget-category-matrix-side-container__padding-bottom: $widget-category-matrix-layout-base__padding / 4;
$widget-category-matrix-layout__margin-top: $base-margin;
$widget-category-matrix-layout__margin-top--large: $base-margin;
$widget-category-matrix-layout__margin-bottom: $base-margin;
$widget-category-matrix-layout__margin-bottom--large: $base-margin;

// Blog Carousel Layout

$widget-blog-carousel-dots__width__height: 1rem;
$widget-blog-carousel-container__padding: ($base-padding * 1.3) 0 0;
$widget-blog-carousel-container__padding--large: ($base-padding * 2.3) 0 0;
$widget-blog-carousel-subheading-wrapper__padding: ($base-padding * 1.2) 0 ($base-padding * 2.3);
$widget-blog-carousel-subheading-link__color: $black-two;
$widget-blog-carousel-subheading-link__font-family: $montserrat;
$widget-blog-carousel-subheading-link__text-decoration-color: $very-light-pink;
$widget-blog-carousel-subheading-link__text-align: center;
$widget-blog-carousel-subheading-link__text-align--large: right;
$widget-blog-carousel-subheading-link__border-top: $border solid $very-light-pink;
$widget-blog-carousel-item__margin--large: 0.7rem;
$widget-blog-carousel-slick-dots__padding: ($base-padding * 1.7) 0;
$widget-blog-carousel-slick-dots-position__top: 100%;
$widget-blog-carousel-slick-dots__margin: 0 $base-margin / 4;
$widget-blog-carousel-slick-dots-button__background-color: rgba($black-two, 0.3);
$widget-blog-carousel-slick-dots-button-active__background-color: $black-two;
$widget-slick-blog-carousel__margin-top: $base-margin * 1.3;
$widget-slick-blog-carousel__margin-top--large: $base-margin * 3;
$widget-slick-blog-carousel__margin--large: ($base-margin * 2.3) ($base-margin * -0.7) 0 ($base-margin * -0.7);
$widget-slick-blog-carousel__padding-bottom: $base-padding * 3;
$widget-slick-blog-carousel__padding-bottom--large: $base-padding * 4;
$widget-blog-carousel-layout__margin-top: 0;
$widget-blog-carousel-layout__margin-top--large: 0;
$widget-blog-carousel-layout__margin-bottom: 0;
$widget-blog-carousel-layout__margin-bottom--large: 0;
$widget-blog-carousel-image-wrapper__width: 5.7rem;
$widget-blog-carousel-image-wrapper__width--large: 8.2rem;
$widget-blog-carousel-image-wrapper__height: 5.2rem;
$widget-blog-carousel-image-wrapper__height--large: 7.1rem;

$slick-slide__max-height: 17rem;

// Category Grid Layout

$widget-category-grid__padding: 0 0 $widget-columns-base__padding / 2;
$widget-layout-category-grid-inner__padding: ($base-padding * 2.6) 0 ($base-padding * 2.8);
$widget-layout-category-grid-inner__padding--large: ($base-padding * 2.6) 0 ($base-padding * 4.8);
$widget-layout-category-grid-diagonal: -184deg;
$widget-layout-category-grid-gradient-background-percentage__first: 55.6%;
$widget-layout-category-grid-gradient-background-percentage__second: 55.8%;
$widget-category-grid-heading-wrapper__margin-bottom: 2.6rem;
$widget-category-grid-layout__margin-top: $base-margin * 3.5;
$widget-category-grid-layout__margin-top--large: $base-margin * 6;
$widget-category-grid-layout__margin-bottom: $base-margin * 3.5;
$widget-category-grid-layout__margin-bottom--large: $base-margin * 4;

// Product Grid Layout

$widget-product-grid-heading__font-weight: $bolder;
$experience-hsngassets-producttile__border-top: $border solid $pale-liliac;

$widget-product-grid-heading__padding: ($base-padding * 1.2) 0 ($base-padding * 2.2);
$widget-product-grid-heading__padding--large: ($base-padding * 2.5) 0 0;
$widget-product-grid-heading__margin-bottom--large: $base-margin * 2;

$experience-components__margin--large: 0 ($base-margin * -1);
$experience-hsngassets-producttile__width--large: 20%;
$experience-hsngassets-article-producttile__width--large: 33.33%;
$experience-hsngassets-producttile__width--medium-large: 25%;
$experience-hsngassets-producttile__padding--large: 0 $base-padding;
$experience-hsngassets-producttile__margin-bottom--large: $base-margin * 2;

$widget-product-grid-layout__margin-top: $base-margin;
$widget-product-grid-layout__margin-top--large: $base-margin;
$widget-product-grid-layout__margin-bottom: $base-margin;
$widget-product-grid-layout__margin-bottom--large: $base-margin;

// Affiliate Grid Layout

$widget-affiliate-grid-layout__margin-top: $base-margin;
$widget-affiliate-grid-layout__margin-top--large: $base-margin;
$widget-affiliate-grid-layout__margin-bottom: $base-margin;
$widget-affiliate-grid-layout__margin-bottom--large: $base-margin;

$widget-affiliate-grid-heading__padding: ($base-padding * 2) 0;
$widget-affiliate-grid-heading__padding--large: ($base-padding * 2.5) 0 0;
$widget-affiliate-grid-heading__font-weight: normal;
$widget-affiliate-grid-heading__border: $border solid #eee;

$widget-affiliate-grid-inner-wrapper__padding-top: ($base-padding * 2);

// Article Grid Layout

$widget-article-grid__padding: 0 0 2rem;
$widget-article-grid__padding--large: 0 0 3rem;

// Double Column Layout

$widget-double-column-base__pading: $base-padding * 0.7;
$widget-double-column__pading: 0 0 $widget-columns-base__padding / 2;
$widget-double-column__margin-top: $base-margin;
$widget-double-column__margin-top--large: $base-margin;
$widget-double-column__margin-bottom: $base-margin;
$widget-double-column__margin-bottom--large: $base-margin;

// Article Grid Layout

$widget-article-three-columns-base__margin: ($widget-columns-base__padding * 3) / 4;
$widget-article-grid-layout__margin-top: $base-margin;
$widget-article-grid-layout__margin-top--large: $base-margin;
$widget-article-grid-layout__margin-bottom: $base-margin;
$widget-article-grid-layout__margin-bottom--large: $base-margin;

// Accordion

$widget-accordion-layout__flex--large: 0 0 50%;
$widget-accordion-layout__margin-top: $base-margin;
$widget-accordion-layout__margin-top--large: $base-margin;
$widget-accordion-layout__margin-bottom: $base-margin;
$widget-accordion-layout__margin-bottom--large: $base-margin;

// Page Type Layout

$widget-article-page-layout__max-width--large: 101rem;
$widget-article-page-layout__width--large: 70%;

// IE compatibility
$ie-two-columns-layout__flex--large: 0 0 49.5%;
$ie-three-columns-layout__flex--large: 0 0 32.66%;
$ie-four-columns-layout__flex--large: 0 0 24.5%;
